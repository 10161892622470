
import Vue from "vue";
import {
  communicationMapActions,
  communicationMapGetters,
  communicationMapMutations,
  communicationMapState
} from "@/store/modules/communication";

import * as types from "@/store/mutation-types";
import * as selectOptions from "@/helpers/selectOptions";

import pluralize from "pluralize";
import SelectOptionComponent from "./SelectFromOptions.vue";
import { authMapActions } from "@/store/modules/auth";
import { cloneDeep } from "lodash";
import {
  atlasfileMapMutations,
  atlasfileMapActions
} from "@/store/modules/atlasfile";
import { ICommunication } from "@/store/modules/communication/types";

interface SingleCommunicationData {
  deliveryMethods: {
    label: string;
    value: string;
  }[];
  communicationPriorities: {
    label: string;
    value: string;
  }[];
  communicationStatus: Record<string, any>[];
  countDownTimer: any;
  imagePreviewToken: string;
  predefinedCommunication: string;
  predefinedCommunicationOptions: any[];
  acknowlegmentOptions: any[];
}

export default Vue.extend({
  name: "singleCommunicationFields",
  components: {
    ContentEditor: () => import("@/components/TinyEditor/TinyEditor.vue"),
    SelectSearchDocuments: () =>
      import("@/components/FormFields/SelectSearchDocuments.vue"),
    Recipients: () => import("./Receipients.vue"),
    AttachmentUploader: () =>
      import("@/components/AttachmentUploader/AttachmentUploader.vue")
    // CommunicationTemplateViewer: () =>
    //   import("@/components/communication/CustomCommunicationTemplateViewer.vue")
  },
  props: {
    isNew: {
      type: Boolean,
      default: true
    },
    communicationId: {
      type: String,
      required: true,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    "editing.status": {
      handler(newVal) {
        if (newVal === "draft") {
          const { _id } = this.editing;
          const communicationPreviouslySaved = this.getCommunicationById(_id);
          const initialValue = communicationPreviouslySaved
            ? communicationPreviouslySaved.deliveryDate
            : "";
          this.editField({ key: "deliveryDate", value: initialValue });
        }
      }
    },
    "editing.sendImmediate": {
      handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          const value = "scheduled";
          this.editField({ key: "status", value });
        }
      }
    }
  },
  mounted() {
    const subjectField = this.$refs.field_subject as any;
    if (subjectField) {
      this.$nextTick(() => {
        if (this.editing && this.editing.status !== "scheduled") {
          subjectField.focusFieldRef();
        }
      });
    }
  },
  data(): SingleCommunicationData {
    return {
      deliveryMethods: selectOptions.communicationDeliveryMethods,
      communicationStatus: selectOptions.communicationStatus,
      communicationPriorities: selectOptions.communicationPriority,
      acknowlegmentOptions: selectOptions.acknowledgementOptions,
      countDownTimer: null,
      imagePreviewToken: "",
      predefinedCommunication: "",
      predefinedCommunicationOptions: []
    };
  },
  methods: {
    ...authMapActions(["authGetAdminPreviewToken"]),
    ...communicationMapMutations({
      editField: types.SET_EDIT_FIELD
    }),
    ...atlasfileMapMutations(["SET_ITEM"]),
    ...communicationMapActions(["getCommunications"]),
    validationChangedHandler(
      errors: string[],
      key?: keyof ICommunication,
      value?: string[] | string
    ) {
      this.$emit(
        "communicationInputHasError",
        !!errors.length || !value?.length
      );
      if (key) {
        this.editField({ key, value });
      }
    },
    ...atlasfileMapActions(["createAtlasfile"]),
    pluralize(str: string, num: number): string {
      return pluralize(str, num, false);
    },
    SelectOptionComponent(): any {
      return SelectOptionComponent;
    },
    save(silent = false): void {
      if (this.isDisabled) return;
      this.$emit("save", silent);
    },
    attachmentUploadHandler(upload: any) {
      if (this.isDisabled) return;
      this.SET_ITEM(upload);
      const attachments = cloneDeep(this.editing.attachments);
      if (Array.isArray(upload)) {
        upload.forEach((doc: any) => attachments.push(doc._id));
      } else {
        attachments.push(upload._id);
      }
      this.editField({ key: "attachments", value: attachments });
    },
    createFormData(file: any) {
      const formData = new FormData();
      formData.append("file", file.blob(), file.filename());
      formData.append("name", "");
      formData.append("isPublic", "false");
      formData.append("category", "None");
      formData.append("type", "attachment");
      return formData;
    },
    async uploadFunction(file: any) {
      let response = await this.createAtlasfile(this.createFormData(file));
      return response;
    }
  },
  computed: {
    ...communicationMapGetters(["getCommunicationById"]),
    ...communicationMapState(["editing", "makingApiRequest"]),
    communicationSaved(): any {
      if (this.isNew) {
        return this.editing;
      } else if (this.editing && this.editing._id) {
        return this.getCommunicationById(this.editing._id);
      }
      return undefined;
    },

    numberOfRecipeints(): number {
      return this.editing.userIds.length;
    }
  }
});
